.sequencing-game {
  padding: 20px;
  background: white;
  border: 1px solid #ddd;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.sequencing-game-layout {
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  border-bottom: #484849 1px solid;
}

.sequencing-game-phases-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  width: 100%;
  font-size: 1em;
}

.sequencing-game-sortable-activities {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 0.8em;
  font-weight: 700;
}

.sequencing-game-sortable-activities li {
  list-style-type: none;
  background: #f8f9fb;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid black;
  cursor: pointer;
}

/* Para telas grandes - entre as fases */
.sequencing-game-phase-column {
  display: flex;
  flex-direction: column;
  position: relative;
}

.between-phases {
  position: absolute;
  right: -20px; /* Ajuste este valor para posicionar corretamente entre as fases */
  top: 50%;
  transform: translateY(-50%);
}


.sequencing-game-phase-column li {
  background: #e1e8f5;
  list-style-type: none;
  color: rgb(0, 0, 0);
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  border-style: solid;
  border-width: 2px;
  border-color: black;
  width: 80%;
}

.sequencing-game-drop-zone {
  border: 2px dashed #737373;
  padding: 10px;
  border-radius: 5px;
  min-height: 50px; /* Altura mínima para evitar colapsar */
  font-size: 0.8em;
  font-weight: 700;
  width: 80%;
}

.sequencing-game-dragging {
  opacity: 0.5;
}

.sequencing-game-correct {
  background: #7cf197 !important;
  border: 5px dotted green;
}

.sequencing-game-out-of-order {
  background: #ffde6f !important; /* Cor laranja */
}

.sequencing-game-incorrect {
  background: #ff3838 !important;
  color: rgb(255, 255, 255) !important;
}

.sequencing-game-fase-correct {
  background-color: #51ff17 !important;
}
.sequencing-game-fase-incorrect {
  background-color: #ff5d5d !important;
}

.sequencing-game-result {
  margin-top: 20px;
  font-size: 1.2em;
  font-weight: 700;
  color: #2a8d09;
}

.sequencing-game-button {
  padding: 10px 20px;
  margin: 10px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.sequencing-game-arrow {
  font-size: 1.5em;
  margin-left: 10px;
}


.sequencing-game-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.sequencing-game-button:hover {
  background-color: #0056b3;
}

@media (max-width: 768px) {
  .sequencing-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
  }

  .sequencing-game-phases-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    width: 100%;
  }

  .sequencing-game-phase-column {
    width: 100%;
    margin-bottom: 10px;
    align-items: center;
  }

  .between-phases {
    position: static; /* Reposiciona a seta para baixo */
    margin-top: 10px; /* Espaço entre a fase e a seta */
  }

  .sequencing-game-sortable-activities {
    width: 100%;
    margin-bottom: 10px;
    align-items: top;
  }

  .sequencing-game-layout {
    display: flex;
    flex-direction: row-reverse;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .sequencing-game-arrow {
    font-size: 1.5em;
    margin-top: 10px;
  }

  .sequencing-game-activities-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    justify-content: start;
    
  }

  .sequencing-game-phases-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: start;
    margin-left: 5px;
    padding-left: 5px;
    border-left: #484849;
    border-left-width: 1px;
    border-left-style: double;
    flex-basis: 130% !important;
  }

  .sequencing-game-sortable-activities {
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .sequencing-game-sortable-activities li {
    width: 100%;
    margin: 5px;
    padding: 5px;
  }

  .sequencing-game-phase-column {
    width: 100%;
    border: #484849 1px solid;
    background-color: antiquewhite;
    margin-bottom: 5px !important;
  }

  .sequencing-game-phase-column li {
    padding: 5px;
    margin-top: 5px;
    margin-bottom: 0px;
    border-radius: 5px;
    cursor: pointer;
    border-style: solid;
    border-width: 2px;
    border-color: black;
    width: 93% !important;
  }

  .sequencing-game-button {
    padding: 5px 15px;
    margin: 5px;
  }

  .sequencing-game-button span {
    display: none !important;
  }

  .sequencing-game-drop-zone {
    margin-top: 5px !important;
    margin-bottom: 0px !important;
    padding: 3px !important;
    width: 93% !important;
  }
}

