.EstatisticasPage-container {
  padding: 20px;
  width: 90%;
}

/* Adiciona um contêiner com overflow para rolagem horizontal */
.EstatisticasPage-tabelaBaralhos {
  width: 100%;
  max-width: 100%;
  overflow-x: auto; /* Permite rolagem horizontal */
}

.EstatisticasPage-tabelaBaralhos .p-treetable-tbody > tr {
  border-bottom: 1px solid #ddd; /* Adiciona uma borda inferior a cada linha */
}

.EstatisticasPage-tabelaBaralhos .p-treetable-tbody > tr:nth-child(odd) {
  background-color: #f9f9f9; /* Cor de fundo para as linhas ímpares */
}

.EstatisticasPage-tabelaBaralhos .p-treetable-tbody > tr:nth-child(even) {
  background-color: #ffffff; /* Cor de fundo para as linhas pares */
}

.EstatisticasPage-tabelaBaralhos .p-treetable-tbody > tr:hover {
  background-color: #f1f1f1; /* Cor de fundo ao passar o mouse */
}

.EstatisticasPage-baralhoNomeColumn {
  white-space: nowrap;  /* Impede a quebra de linha no nome do baralho */
  width: 1%;          /* Permite que a largura se ajuste ao texto */
  max-width: 250px;     /* Define um tamanho máximo */
  text-overflow: ellipsis; /* Exibe "..." se o nome for muito longo */
  overflow: hidden;     /* Garante que o texto extra seja cortado */
}

/* Garante que as outras colunas ocupem o espaço restante */
.EstatisticasPage-columnCenter {
  width: 1%; /* Faz as colunas ocuparem o mínimo de espaço necessário */
  white-space: nowrap; /* Impede quebra de linha */
  text-align: center; /* Alinha o conteúdo ao centro */
}

.p-treetable-tfoot {
  padding: 10px;
  border: 1px solid #ddd; /* Adiciona uma borda ao redor das células */
  text-align: center; /* Centraliza o texto nas colunas */
  font-weight: 700;
}

.EstatisticasPage-baralhoNomeColumn {
  text-align: left !important;
}

.EstatisticasPage-columnCenter {
  text-align: center;
  padding: 10px;
}

.EstatisticasPage-Filtro {
  display: flex;
  flex-direction: row;
  justify-content: first baseline;
  padding: 10px;
  margin-top: 20px;
  border-style: solid;
  border-width: 1px;
  align-items: center;
  gap: 10px;
}

.p-dropdown-items{
  opacity: 1 !important; /* Garante a opacidade completa */
  background-color: white !important ; /* Garante um fundo sólido */
}

.titulo-estatistica-filtro {
  margin: 10px 0 !important;
}

.estatísticas-filtro-selecione {
  padding: 10px;
  border: 1px solid black;
}

.EstatisticasPage-revisoes {
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  gap: 15px;
}

.EstatisticaPage-dropdown {
  background-color: white;
}

.EstatisticasPage-revisoes-graficos {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 15px;
}

.EstatisticasPage-revisoesPorCategoria {
  border: black 1px solid;
  border-radius: 5px;
  padding: 10px;
}

.EstatisticasPage-reviesoesAcertosErros {
  border: black 1px solid;
  border-radius: 5px;
  padding: 10px;
}

.EstatisticasPage-revisoesTable {
  border: black 1px solid;
  text-align: center;
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
}

.EstatisticasPage-revisoesTable th,
.EstatisticasPage-revisoesTable td {
  padding: 12px 15px;
  border: 1px solid #ddd;
}

.EstatisticasPage-revisoesTable th {
  background-color: #f4f4f4;
  color: #333;
}

.EstatisticasPage-revisoesTable tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.EstatisticasPage-revisoesTable tbody tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.EstatisticasPage-revisoesTable .EstatisticasPage-totalRow {
  font-weight: bold;
  background-color: #e6e6e6;
  color: #333;
}

.EstatisticasPage-revisoesTable .EstatisticasPage-totalRow td {
  border-top: 2px solid #333;
}

@media (max-width: 768px) {
  .EstatisticasPage-container {
      margin: 0;
      padding: 0;
      width: 90%;
  }

  .EstatisticasPage-revisoes {
    margin-bottom: 50px !important;
    bottom: 50px;
  }

  .EstatisticasPage-reviesoesAcertosErros {
    padding-bottom: 50px;
  }

  .EstatisticasPage-revisoes-graficos {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      gap: 10px;
  }

  .EstatisticasPage-tabelaBaralhos {
      display: block;
      width: 100%;
      overflow-x: auto; /* Permite rolagem horizontal dentro do contêiner */
  }

  .EstatisticasPage-columnCenter,
  .EstatisticasPage-baralhoNomeColumn {
      padding: 8px; /* Ajusta o padding das colunas em telas pequenas */
      font-size: 14px; /* Reduz o tamanho da fonte para telas pequenas */
      max-width: 50px !important;
      width: 100% !important;
  }

  .EstatisticasPage-container {
      padding: 10px;
  }

  /* Garante que a última coluna tenha espaço */
  .EstatisticasPage-tabelaBaralhos .p-treetable-tbody > tr > td:last-child,
  .EstatisticasPage-tabelaBaralhos .p-treetable-thead > tr > th:last-child {
      display: table-cell !important; /* Garante que a última coluna não seja escondida */
  }
}
