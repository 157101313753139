.arrastar-soltar-container {
  display: flex;
  flex-direction: column;
  background: white;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 800px;
  width: 100%;
  text-align: center;
  gap: 10px;
}

.arrastar-soltar-items-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px; /* Espaçamento entre os itens */
  margin-bottom: 20px;
  margin-top: 10px;
  padding: auto;
}

.arrastar-soltar-item {
  list-style-type: none;
  background: #f8f9fb;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid black;
  cursor: pointer;
}

.arrastar-soltar-category {
  display: inline-block;
  vertical-align: top;
  margin: 0 5px;
  width: 30%;
}

.arrastar-soltar-category h3 {
  margin-bottom: 10px;
}

.arrastar-soltar-drop-zone {
  border: 2px dashed #737373;
  padding: 10px;
  min-height: 50px; /* Alinhado com o SequencingGame */
  background: #f9f9f9;
  border-radius: 5px;
}

.arrastar-soltar-drop-zone .arrastar-soltar-item {
  margin: 5px 0;
  width: 100%; /* Os itens dentro das categorias ocupam 100% da largura da drop zone */
  box-sizing: border-box;
}

.arrastar-soltar-correct {
  background: #7cf197 !important;
  border: 2px dotted green;
}

.arrastar-soltar-incorrect {
  background: #fe0019 !important;
  color: white !important;
}

.arrastar-soltar-out-of-order {
  background: #ffde6f !important; /* Cor laranja */
}

.arrastar-soltar-dragging {
  opacity: 0.5;
}

.arrastar-soltar-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.arrastar-soltar-button {
  padding: 5px 15px;
  margin: 5px;
  border: none;
  border-radius: 8px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.arrastar-soltar-button:hover {
  background-color: #1e5085;
}

#arrastar-soltar-result {
  margin-top: 20px;
  font-size: 1.2em;
}



@media (max-width: 768px) {
  .arrastar-soltar-container {
    margin: 2px;
    padding: 2px;
  }

  .arrastar-soltar-button span {
    display: none !important;
  }

  .arrastar-soltar-category {
    margin:0;
    width: 48%;
  }

  .arrastar-soltar-drop-zone {
    padding: 5px;
  }

  .arrastar-soltar-categories-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .arrastar-soltar-container-titulo {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
