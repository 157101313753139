@font-face {
  font-family: 'Virgil';
  src: url('./fonts/Virgil.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Cascadia';
  src: url('./fonts/Cascadia.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


.app-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

h1{
  background-color: rgb(142, 210, 210);
  text-align: center;
  width: 100%;
}

h2{
  background-color: rgb(156, 164, 216);
  text-align: center;
  width: 100%;
}

h3{
  background-color: rgb(180, 217, 209);
  text-align: center;
  width: 100%;
}


.comentarios-verde {
  background-color: rgb(104, 227, 159);
}

.comentarios-azul {
  width: 100%;
  background-color: rgb(104, 206, 227);
}

.comentarios-amarelo {
  width: 100%;
  background-color: rgb(239, 251, 184);
}

.comentarios-vermelho {
  width: 100%;
  background-color: rgb(253, 171, 171);
}

.comentarios-laranja {
  width: 100%;
  background-color: rgb(255, 192, 119);
}

.comentarios-lilas {
  width: 100%;
  background-color: rgb(227, 171, 253);
}

.comentarios-rosa {
  width: 100%;
  background-color: rgb(255, 133, 214);
}

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.menu-visible .content {
  margin-left: 250px;
}

.menu-hidden .content {
  margin-left: 0;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding-top: 60px; /* Espaço para o Header */
}

.menu {
  width: 250px;
  transition: transform 0.3s, width 0.3s;
}

.menu-hidden .menu {
  transform: translateX(-250px);
  width: 0;
}

p {
  font-size: 14pt !important;
}