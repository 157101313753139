
  .preencher-item-categoria-container {
    max-width: 900px;
    margin: 10px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    align-items: center;
    text-align: center;
    justify-content: center;
  }
  
  .preencher-item-categoria-container h1 {
    text-align: center;
    color: #444;
  }
  
  .preencher-item-categoria-view-options {
    display: none;
    text-align: right;
    margin-bottom: 20px;
    align-items: start;
    justify-content: right;
  }
  
  .preencher-item-categoria-container button {
    padding: 10px 20px;
    margin: 5px;
    border: none;
    background-color: #6c757d;
    color: white;
    cursor: pointer;
    border-radius: 4px;
    font-size: 16px;
  }

  .PreencherItemCategoriaBtAvaliar {
    background-color: blue !important;
  }

  .PreencherItemCategoriaBtAvaliar:hover {
    background-color: rgb(64, 80, 255) !important;
  }
  
  .preencher-item-categoria-container button:hover {
    background-color: #5a6268;
  }
  
  .preencher-item-categoria-horizontal-view-old {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 25px;
    align-items: center;
  }

  .preencher-item-categoria-horizontal-view {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    gap: 15px;
    align-items: top;
    width: 100%;
  }
  
  .preencher-item-categoria-vertical-view {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: top;
  }
  
  .preencher-item-categoria-category {
    background-color: #fafafa;
    border: 1px solid #ddd;
    margin: 10px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .preencher-item-categoria-category h2 {
    margin-top: 0;
    color: #555;
  }
  
  .preencher-item-categoria-item-input {
    width: 95%;
    padding: 10px;
    margin: 5px 0px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
  }

  .preencher-item-categoria-item-input.preencher-item-categoria-item-input-em-ordem {
    width: 80% !important;
    padding: 3px;
    margin: 2px;
  }

  .preencher-item-categoria-grid-item-cell {
    align-items: center;
  }
  
  .preencher-item-categoria-item-input.preencher-item-categoria-correct {
    border-color: green;
    color: green;
  }
  
  .preencher-item-categoria-item-input.preencher-item-categoria-incorrect {
    border-color: red;
    color: red;
  }
  
  .preencher-item-categoria-results {
    margin-top: 20px;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .preencher-item-categoria-results div {
    margin-bottom: 10px;
  }
  
  .preencher-item-categoria-category .preencher-item-categoria-item-input-container {
    display: flex;
    flex-direction: row;
  }

  .preencher-item-categoria-item-input-container {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 5px;
    flex-direction: row;
  }

  .preencher-item-categoria-item-input-container-em-ordem {
    display: flex;
    gap: 5px;
    flex-direction: column;
  }

  .preencher-item-categoria-item-input {
    width: 100px !important;
  }

  .preencher-item-categoria-item-input-em-ordem {

  }
  
  .preencher-item-categoria-category .preencher-item-categoria-item-input-container textarea {
    margin-bottom: 4px;
    width: 20%;
  }
  
  .preencher-item-categoria-grid-header-cell {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: #444 2px solid;
  }
  
  .preencher-item-categoria-grid-item-cell {
    display: flex;
    flex-direction: column;
  }

  .preencher-item-categoria-category-container {
    border: 1px solid black;
    border-radius: 8px;
    padding: 5px;
  }
  .preencher-item-categoria-actions {
    margin-top: 10px;
  }


  
  @media only screen and (max-width: 500px) {
    .preencher-item-categoria-horizontal-view {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
    }
  }

  @media (max-width: 768px) {
    .preencher-item-categoria-actions span {
      display: none;
    }

    .preencher-item-categoria-item-input {
      padding: 5px;
    }

  }