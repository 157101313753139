.preencher-item-fases-container {
    max-width: var(--max-width-componente);
    width: 100%;
    margin: 0px;
    padding: 10px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .preencher-item-fases-phase-container {
    display: flex;
    align-items: center;
  }
  
  .preencher-item-fases-phase {
    background-color: #f9f9f9;
    width: calc(100%-20px);
    max-width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
  }
  
  .correct-phase {
    background-color: #d4edda;
  }
  
  .incorrect-phase {
    background-color: #f8d7da;
  }
  
  .correct-item {
    color: green;
  }
  
  .incorrect-item {
    color: red;
  }
  
  .wrong-order-item {
    color: rgb(255, 174, 0);
  }

  .preencher-item-fases-textarea- {
    margin-bottom: 0px;
    border-radius: 5px;
    max-width: 100%;
    width: 90%;
    background-color: #ffffff;
    font-weight: 700;
  }
  
  .preencher-item-fases-textarea-correct-item {
    margin-bottom: 0px;
    border-radius: 5px;
    max-width: 100%;
    width: 90%;
    background-color: #78ff81;
    font-weight: 700;
  }
  
  .preencher-item-fases-textarea-incorrect-item {
    margin-bottom: 0px;
    border-radius: 5px;
    max-width: 100%;
    width: 90%;
    background-color: #ff8a8a;
    font-weight: 700;
  }
  
  .preencher-item-fases-textarea-wrong-order-item {
    margin-bottom: 0px;
    border-radius: 5px;
    max-width: 100%;
    width: 90%;
    background-color: #f0f59c;
    font-weight: 700;
  }
  
  .preencher-item-fases-phases-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: first baseline;
    gap: 15px;
  }
  
  .preencher-item-fases-phase ul {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: calc(100%-10px);
    margin: 0px;
    padding: 0px;
    gap: 10px;
  }
  
  .preencher-item-fases-phase ul li {
    list-style-type: none;
    display: flex;
    align-items: center;
  }
  
  .preencher-item-fases-arrow {
    font-size: 2rem;
    margin-left: 10px;
  }
  
  .preencher-item-fases-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;
    margin-top: 15px;
  }
  
  .preencher-item-fases-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .preencher-item-fases-buttons button:hover {
    background-color: #0056b3;
  }
  
  .correct-icon {
    color: green;
  }
  
  .incorrect-icon {
    color: red;
  }
  
  .wrong-order-icon {
    color: rgb(255, 247, 0) !important;
  }

  .preencher-item-fases-textarea-,
  .preencher-item-fases-textarea-correct-item,
  .preencher-item-fases-textarea-incorrect-item,
  .preencher-item-fases-textarea-wrong-order-item {
    resize: none; /* O redimensionamento horizontal está desativado */
    max-height: 150px; /* Altura máxima para evitar crescimento excessivo */
    height: 10px;
    padding: 4px;
  }

  
  @media (max-width: 600px) {
    .preencher-item-fases-arrow {
      margin-top: 10px;
      margin-left: 0;
    }

    .preencher-item-fases-container h3{
      margin: 10px 0;
    }
  
    .preencher-item-fases-phase-container {
      flex-direction: column;
      width: calc(100%-25px);
      padding: 5px 25px 5px 25px;
    }

    .preencher-item-fases-phase {
      width: 80%;
      align-items: center;
      padding: 5px 25px 15px 25px;
      margin: 0px !important;
    }

    .preencher-item-fases-phase ul {
      width: 100%;
      align-items: center;
    }

    .preencher-item-fases-phase h4 {
      margin: 10px 0 10px 0;
    }

    .preencher-item-fases-phase ul li {
      width: 100%;
      align-items: center;
    }

    .preencher-item-fases-phase ul li text {
      width: 100%;
      align-items: center;
    }
  
    .preencher-item-fases-phases-container {
      display: flex;
      flex-direction: column;
      justify-content: first baseline;
      gap: 5px;
      padding: 10px;
    }

    .preencher-item-fases-textarea-,
    .preencher-item-fases-textarea-correct-item,
    .preencher-item-fases-textarea-incorrect-item,
    .preencher-item-fases-textarea-wrong-order-item {
      padding: 2px;
    }

    .preencher-item-fases-button span {
      display: none !important;
    }
  
  }
  