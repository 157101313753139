.ordenacao-editor {
    padding: 20px;
  }
  
  .ordenacao-editor-stages {
    margin-bottom: 1rem;
  }
  
  .ordenacao-editor-stage {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
  }
  
  .ordenacao-editor-stage-controls {
    display: flex;
    align-items: center;
    margin-left: 1rem;
  }
  
  .ordenacao-editor-actions {
    display: flex;
    justify-content: space-between;
  }
  